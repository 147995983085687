import './App.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AllNavBar from './PatientApps/Navbar';
import "./index.css";

// Lazy load components
const TherapistLoginForm = lazy(() => import('./TherapistApps/LogIn/TherapistLogin'));
const TherapistRegistrationForm = lazy(() => import('./TherapistApps/Registration/TherapistRegistration'));
const PatientRegistrationForm = lazy(() => import('./PatientApps/PatientRegistration/PatientRegistration'));
const PatientLoginForm = lazy(() => import('./PatientApps/PatientLogin/PatientLogin'));
const PatientDashboard = lazy(() => import('./PatientApps/Account/PatientDashboard'));
const SearchPage = lazy(() => import('./PatientApps/SearchPage/SearchPage'));
const FrontPage = lazy(() => import('./PatientApps/FrontPage/Frontpage'));
const BookingComplete = lazy(() => import('./PatientApps/SearchPage/BookingCompletePage'));
const VideoChatPage = lazy(() => import('./TherapistApps/VideoChat/VideoPageApp'));
const WeekView = lazy(() => import('./TherapistApps/TherapistDashboard/TherapistScheduler/WeekView'));
const Therapist_ClientList = lazy(() => import('./TherapistApps/TherapistDashboard/ClientList/ClientList'));
const TherapistDashboard = lazy(() => import('./TherapistApps/TherapistDashboard/TherapistDashboard'));
const ReviewCarousel = lazy(() => import('./PatientApps/Reviews/ReviewsCards'));
const LineCallback = lazy(() => import('./TherapistApps/Registration/LineCallback'));
const PatientVideoPage = lazy(() => import('./PatientApps/Account/PatientVideo/PatientVideoPage'));
const Sender = lazy(() => import('./TherapistApps/VideoChat/CalendarRequest/RequestSender'));
const Receiver = lazy(() => import('./PatientApps/Account/PatientVideo/CalendarRequestPopup/CalendarReceiver'));
const CompanyOverview = lazy(() => import('./PatientApps/CompanyPage/CompanyPage'));
const TermsofUse = lazy(() => import('./PatientApps/CompanyPage/termsofuse'));
const LegalDisclosure = lazy(() => import('./PatientApps/FrontPage/ServiceAgreement/Serviceagreement'));
const VideoControls = lazy(() => import('./TherapistApps/VideoChat/VideoControl/VideoControl'));
const ChargeButton = lazy(() => import('./TherapistApps/TherapistDashboard/History/patientcharge'));
const SaveCCInfo = lazy(() => import('./PatientApps/Account/RegisterCardInfo'));
const TherapistPaymentApp = lazy(() => import('./TherapistApps/TherapistDashboard/Payments/payments'));
const BookingsCalendarModal = lazy(() => import('./PatientApps/SearchPage/CalendarModal'));
const VideoChatPagetest = lazy(() => import('./TherapistApps/VideoChat/videoapptest'));
const PatientVideoV2 = lazy(() => import('./PatientApps/Account/PatientVideo/PatientVideoPageV2'));
const AdminLogin = lazy(() => import('./TherapistApps/Admin/AdminLogin'));
const PatientResetPassword = lazy(() => import('./PatientApps/PatientLogin/ResetPassword'));
const TherapistResetPassword = lazy(() => import('./TherapistApps/LogIn/ResestPassword'));
const LandingPage = lazy(() => import('./TherapistApps/HomePage/FrontPage'));

// Loading component for Suspense fallback
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
  </div>
);

const getIsTherapistDomain = () => {
  const hostname = window.location.hostname;
  return hostname === 'localhost' || hostname === '127.0.0.1'
    ? window.location.pathname.startsWith('/therapist')
    : hostname === 'care.ascnau.com';
};

const App = () => {
  const isTherapistDomain = getIsTherapistDomain();

  React.useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      const currentPath = window.location.pathname;
      const currentHostname = window.location.hostname;
      
      // If we're on the care domain but it's a patient route
      if (currentHostname === 'care.ascnau.com') {
        if (['/patientlogin', '/patientregister', '/patientdashboard', '/searchtherapist'].some(route => currentPath.startsWith(route))) {
          window.location.href = `https://www.ascreforme.com${currentPath}`;
        }
      } 
      // If we're on the patient domain but it's a care route
      else if (currentHostname === 'www.ascreforme.com') {
        if (['/login', '/register', '/dashboard', '/session'].some(route => currentPath.startsWith(route))) {
          window.location.href = `https://care.ascnau.com${currentPath}`;
        }
      }
    }
  }, []);

  const commonRoutes = [
    <Route key="callback" path="/callback" element={<LineCallback/>} />,
    <Route key="company" path="/companyinformation" element={<CompanyOverview/>} />,
    <Route key="terms" path="/termsofuse" element={<TermsofUse/>} />,
    <Route key="disclosure" path="/disclosure" element={<LegalDisclosure/>} />
  ];

  const therapistRoutes = [
    <Route key="therapist-home" exact path='/' element={<LandingPage/>} />,
    <Route key="therapist-login" exact path='/login' element={<TherapistLoginForm/>} />,
    <Route key="therapist-register" exact path='/register' element={<TherapistRegistrationForm/>} />,
    <Route key="therapist-dashboard" exact path='/dashboard' element={<TherapistDashboard/>} />,
    <Route key="therapist-scheduler" exact path='/testing/scheduler' element={<WeekView/>} />,
    <Route key="therapist-clients" exact path='/testing/clientlist' element={<Therapist_ClientList/>} />,
    <Route key="therapist-video" path="/session/videochat/:session_uuid" element={<VideoChatPagetest/>} />,
    <Route key="therapist-calendar" path="/testing/calendarrequest" element={<Sender/>} />,
    <Route key="video-control" path="/videocontrol" element={<VideoControls/>} />,
    <Route key="charge" path="/testing/chargebutton" element={<ChargeButton/>} />,
    <Route key="payment" path="/therapistpayment" element={<TherapistPaymentApp/>} />,
    <Route key="video-test" path="/testing/videotestpage" element={<VideoChatPagetest/>} />,
    <Route key="video-chat" path="/testingvideochat" element={<VideoChatPagetest/>} />,
    <Route key="admin" path="/testing/adminlogin" element={<AdminLogin/>} />,
    <Route key="reset-pw" path="/pwreset" element={<TherapistResetPassword/>} />
  ];

  const patientRoutes = [
    <Route key="patient-home" exact path='/' element={<FrontPage/>} />,
    <Route key="search" exact path="/searchtherapist" element={<SearchPage/>} />,
    <Route key="patient-login" exact path='/patientlogin' element={<PatientLoginForm/>} />,
    <Route key="patient-register" exact path='/patientregister' element={<PatientRegistrationForm/>} />,
    <Route key="patient-dashboard" exact path='/patientdashboard' element={<PatientDashboard/>} />,
    <Route key="booking-complete" exact path='/bookingcomplete' element={<BookingComplete/>} />,
    <Route key="reviews" exact path='/reviews' element={<ReviewCarousel/>} />,
    <Route key="patient-video" path="/patient/videochat" element={<PatientVideoPage/>} />,
    <Route key="patient-room" path="/session/room/:session_uuid" element={<PatientVideoV2/>} />,
    <Route key="patient-receive" path="/patientreceive" element={<Receiver/>} />,
    <Route key="patient-cc" path="/patientCC" element={<SaveCCInfo/>} />,
    <Route key="calendar-modal" path="/calendarmodal" element={<BookingsCalendarModal/>} />,
    <Route key="patient-video-v2" path="/patientvideoV2" element={<PatientVideoV2/>} />,
    <Route key="patient-reset" path="/patientpwreset" element={<PatientResetPassword/>} />
  ];

  return (
    <div className="App">
      <Router>
        <AllNavBar />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {isTherapistDomain ? [...therapistRoutes, ...commonRoutes] : [...patientRoutes, ...commonRoutes]}
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;